import React, { useState, useEffect, useRef } from 'react';

import { BsBookmark, BsBookmarkFill, BsGem, BsChevronRight, BsChevronLeft } from 'react-icons/bs';

import arrowProductLeft from '../examples/icon/arrowProduct.png'
import arrowProductRight from '../examples/icon/arrowProduct.png';
import { ProductColors } from '../ProductColors';

import '../examples/scss/Product.scss';

const Product = ({ product, handleFavoriteQuantityChange, favorite }) => {
    const [hoveredCard, setHoveredCard] = useState(null);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [isGemSelected, setIsGemSelected] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const imageArray = Array.isArray(product.images) ? product.images : [];

    const sizeContainerRef = useRef(null);
    const [isScrollable, setIsScrollable] = useState(false);

    useEffect(() => {
        if (sizeContainerRef.current) {
            const container = sizeContainerRef.current;
            setIsScrollable(container.scrollWidth > 200);
        }
    }, [hoveredCard]);

    const handleFavoriteClick = (e, productId, isFavorite) => {
        e.stopPropagation();
        setIsBookmarked((prevFavorites) => {
            const updatedFavorites = { ...prevFavorites, [productId]: !isFavorite };

            handleFavoriteQuantityChange(productId, isFavorite ? 'decrease' : 'increase');

            return updatedFavorites;
        });
    };

    useEffect(() => {
        if (favorite && favorite.products && Array.isArray(favorite.products)) {
            const favoriteIds = favorite.products.reduce((acc, item) => {
                acc[item.id] = true;
                return acc;
            }, {});
            setIsBookmarked(favoriteIds);
        }
    }, [favorite]);

    const handleProductClick = (productUri) => {
        window.location.pathname = productUri;
    };

    const handlePreviousImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : imageArray.length - 1));
    };

    const handleNextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex < imageArray.length - 1 ? prevIndex + 1 : 0));
    };

    const scrollLeft = () => {
        if (sizeContainerRef.current) {
            sizeContainerRef.current.scrollBy({
                left: -100,
                behavior: 'smooth'
            });
        }
    };

    const scrollRight = () => {
        if (sizeContainerRef.current) {
            sizeContainerRef.current.scrollBy({
                left: 100,
                behavior: 'smooth'
            });
        }
    };

    const colorPropertiesId = product.properties
        .filter(prop => prop.type === 'color')
        .map(prop => prop.propertyId);

    const materialValue = product.properties
        .filter(prop => prop.type === 'material')
        .map(prop => prop.value);

    const sizeValue = product.properties
        .filter(prop => prop.type === 'size')
        .map(prop => prop.value);

    return (
        <>
            {product && (
                <div
                    className={`product-card ${hoveredCard ? 'hovered' : ''}`}
                    onMouseEnter={() => setHoveredCard(true)}
                    onMouseLeave={() => setHoveredCard(false)}
                >
                    <div
                        className='product-top-card'
                        onClick={() => handleProductClick(product.uri)}
                    >
                        {imageArray.length > 1 && (
                            <>
                                {/* Left Arrow */}
                                <img
                                    src={arrowProductLeft}
                                    alt="Arrow Left"
                                    className="product-arrow-left"
                                    onClick={(e) => {
                                        handlePreviousImage()
                                        e.stopPropagation();
                                    }}
                                />
                                {/* Right Arrow */}
                                <img
                                    src={arrowProductRight}
                                    alt="Arrow Right"
                                    className="product-arrow-right"
                                    onClick={(e) => {
                                        handleNextImage()
                                        e.stopPropagation();
                                    }}
                                />
                            </>
                        )}

                        {/* Product Image */}
                        <img
                            className="product-image"
                            src={imageArray[currentIndex]}
                            alt="product image"
                        />

                        {/* Footer */}
                        <div className="product-image-footer">

                            <span
                                className="icon-bookmark"
                                onClick={(e) => {
                                    handleFavoriteClick(e, product.id, isBookmarked[product.id])
                                }}
                                style={{ cursor: "pointer" }}>
                                {isBookmarked[product.id] ?
                                    <BsBookmarkFill className="favorite-icon filled" /> :
                                    <BsBookmark className="favorite-icon" />}
                            </span>
                            <span
                                className={`icon-gem ${isGemSelected ? "selected" : ""}`}
                                onClick={(e) => {
                                    setIsGemSelected(!isGemSelected)
                                    e.stopPropagation();
                                }}
                                style={{ cursor: "pointer", position: "relative" }}
                            >
                                {isGemSelected && <span className="gem-background"></span>}
                                <BsGem className="gem-icon" />
                            </span>
                        </div>
                    </div>
                    {/* Product Name and News Text */}
                    <div className="product-description">
                        <p className='product-material'>{materialValue}</p>
                        <h2 className="product-title">{product.name}</h2>
                        {hoveredCard === true ? (
                            <div className="product-size-container">
                                {product.sizes && Object.values(product.sizes).length > 0 ? (
                                    <>
                                        {isScrollable && (
                                            <BsChevronLeft onClick={scrollLeft} />
                                        )}
                                        <div ref={sizeContainerRef} className="product-size-card">
                                            {Object.values(product.sizes).map((size, index) => (
                                                <div key={index} className="card-size-box">
                                                    {size.value}
                                                </div>
                                            ))}
                                        </div>
                                        {isScrollable && (
                                            <BsChevronRight onClick={scrollRight} />
                                        )}
                                    </>
                                ) : (
                                    sizeValue.length > 0 && <div className="size-box">{sizeValue}</div>
                                )}
                            </div>
                        ) : (
                            <p className="product-price">{product.price}</p>
                        )}

                        {colorPropertiesId.map((propertyId, index) => {
                            const color = ProductColors[propertyId];

                            if (color) {
                                // If it's an array (multiple colors), render each color as a split circle
                                if (Array.isArray(color)) {
                                    return (
                                        <div
                                            key={index}
                                            className="color-circle-split"
                                            style={{
                                                background: `linear-gradient(to right, ${color[0]} 50%, ${color[1]} 50%)`
                                            }}
                                            aria-label={`Color: ${color[0]} / ${color[1]}`}
                                        />
                                    );
                                }
                                // If it's a single color, render just that one color
                                return (
                                    <div
                                        key={index}
                                        className="color-circle"
                                        style={{ backgroundColor: color }}
                                        aria-label={`Color: ${color}`}
                                    />
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
            )}
        </>
    );
}

export default Product;