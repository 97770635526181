import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import 'sass';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://47df635802704b5081f5fd6e02b71cdd@o358044.ingest.us.sentry.io/4509074016108544",
    integrations: [Sentry.browserTracingIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "https://www.astrid-agnes.com/"],
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<BrowserRouter>
    <App/>
</BrowserRouter>
);
