import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BsList, BsSearch, BsBookmark, BsXLg, BsGem, BsChevronDown, BsChevronRight } from "react-icons/bs";

import DropdownMenuComponent from "./navigation/DropdownMenuComponent";
import { CountryLanguageDropdown } from "./CountryLanguageSelector";

import { useSize } from "./WindowSize";

import astridagnes from './examples/icon/astrid&agnes_loggo_vit_HU_200x200.png'

import '../style/navigation.scss'

const Navbar = ({ navigations, handleShowSearch, templates, handleShowMiniCart, handleShowFavorits, activeCountry, activeLanguage, itemInCart, favorite }) => {
    const windowsize = useSize();

    const [menuVisible, setMenuVisible] = useState(false);
    const [activeNavbar, setActiveNavbar] = useState(false);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const activeTemplate =
        templates?.find(
            (template) => template.active && template.templateId === 9
        ) || null;

    useEffect(() => {
        const handleScroll = () => {
            setActiveNavbar(window.scrollY >= 100);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (menuVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [menuVisible]);

    useEffect(() => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <div className='navigation-container'>
            <div className="px-4 navigation-header-container">
                <div className="row w-100">
                    <div className="col-4 d-flex justify-content-start align-items-center">
                        {windowsize >= 441 && (
                            <div class="trustpilot-widget" data-locale="sv-SE" data-template-id="5406e65db0d04a09e042d5fc" data-businessunit-id="615c4b8fd9362e001de1db34" data-style-height="28px" data-style-width="100%" data-theme="dark">
                                <a href="https://se.trustpilot.com/review/www.astrid-agnes.com" target="_blank" rel="noopener">Trustpilot</a>
                            </div>
                        )}
                        {windowsize <= 440 && (
                            <div class="trustpilot-widget" data-locale="sv-SE" data-template-id="5406e65db0d04a09e042d5fc" data-businessunit-id="615c4b8fd9362e001de1db34" data-style-height="20px" data-style-width="100%">
                                <a href="https://se.trustpilot.com/review/www.astrid-agnes.com" target="_blank" rel="noopener">Trustpilot</a>
                            </div>
                        )}
                    </div>
                    <div
                        className="col-4 d-flex align-items-center justify-content-center"
                        style={{ paddingTop: '15px' }}
                    >
                        {activeTemplate ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: activeTemplate.data,
                                }}
                            />
                        ) : null}
                    </div>
                    <div className="col-4 d-flex justify-content-end align-items-center">
                        <p className="navigation-header-text-b2b">
                            <a href="https://b2b.astrid-agnes.com" target="_blank" rel="noopener noreferrer">
                                B2B
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div className="navigation-select-container">
                {/* Navbar */}
                <div className={` ${activeNavbar ? 'navigation-hamburger active' : 'navigation-hamburger'} ${menuVisible ? 'show-navigation' : ''}`}>
                    <div className="row w-100 px-4">
                        <div className="col-4 px-4 d-flex justify-content-start align-items-center">
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <div className="navigation-text-div">
                                    {!menuVisible ? (
                                        <BsList size={27} className="icon-BsList" onClick={toggleMenu} />
                                    ) : (
                                        <BsXLg size={27} className="icon-BsXLg" onClick={toggleMenu} />
                                    )}
                                </div>
                                {windowsize <= 1070 && (
                                    <div className="icon-BsSearch">
                                        <BsSearch size={22} color="white" onClick={handleShowSearch} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-4 d-flex align-items-center justify-content-center">
                            <div className="navigation-hamburger-center">
                                <div className="navigation-logo-div">
                                    <Link to={"/" + activeLanguage.shortCode + "-" + activeCountry.iso + "/"}><img src={astridagnes} alt="Astrid & Agnes Logo" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 d-flex justify-content-end align-items-center">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {windowsize >= 1070 && (
                                    <div>
                                        <CountryLanguageDropdown activeCountry={activeCountry} activeLanguage={activeLanguage} />
                                    </div>
                                )}
                                {windowsize >= 1070 && (
                                    <>
                                        <BsSearch
                                            size={27}
                                            color="white"
                                            onClick={handleShowSearch}
                                            style={{ marginLeft: '20px', marginRight: '20px' }}
                                        />
                                        <BsBookmark
                                            size={27}
                                            color="white"
                                            onClick={handleShowFavorits}
                                            style={{ marginRight: favorite.products.length === 0 ? '20px' : '0' }}
                                        />
                                        {favorite.products.length > 0 && (
                                            <div className="totalInFavorites">
                                                {favorite.products.length}
                                            </div>
                                        )}
                                        <BsGem
                                            size={27}
                                            color="white"
                                            onClick={handleShowMiniCart}
                                        />
                                        {itemInCart.products.length > 0 && (
                                            <div className="addToCart">
                                                {itemInCart.products.reduce((total, product) => total + product.quantity, 0)}
                                            </div>
                                        )}
                                    </>
                                )}
                                {windowsize <= 1069 && (
                                    <>
                                        <BsBookmark
                                            size={22}
                                            color="white"
                                            onClick={handleShowFavorits}
                                            style={{ marginRight: favorite.products.length === 0 ? '20px' : '0' }}
                                        />
                                        {favorite.products.length > 0 && (
                                            <div className="totalInFavorites">
                                                {favorite.products.length}
                                            </div>
                                        )}
                                        <BsGem
                                            size={22}
                                            color="white"
                                            onClick={handleShowMiniCart}
                                        />
                                        {itemInCart.products.length > 0 && (
                                            <div className="addToCart">
                                                {itemInCart.products.reduce((total, product) => total + product.quantity, 0)}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {menuVisible && (
                    <div>
                        <div style={{ width: '100%' }}>
                            <DropdownMenuComponent navigations={navigations} templates={templates} activeCountry={activeCountry} activeLanguage={activeLanguage} setMenuVisible={setMenuVisible} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Navbar;


const CountryLanguageDropdowns = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const countries = [
        { name: "Sweden", id: 205, iso: 'SE', language: "sv" },
        { name: "Norway", id: 160, iso: 'NO', language: "en" },
        { name: "Germany", id: 80, iso: 'DE', language: "en" },
        { name: "Italy", id: 105, iso: 'IT', language: "en" }
    ];
    const languages = [
        { name: "Swedish", id: 1, shortCode: 'sv', country: "SE" },
        { name: "English", id: 2, shortCode: 'en', country: props.activeCountry.iso }
    ];

    return (
        <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
            <div
                onClick={() => setDropdownOpen(!dropdownOpen)}
                style={{
                    fontSize: "14px",
                    textTransform: "uppercase",
                    color: "white",
                    cursor: "pointer",
                    border: "1px solid white",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    minWidth: "128px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "5px",
                    backgroundColor: "transparent",
                    fontFamily: 'Monsterrat-Regular'
                }}
            >
                {props.activeCountry.name} / {props.activeLanguage.shortCode}
                {dropdownOpen ? <BsChevronDown /> : <BsChevronRight />}
            </div>

            {dropdownOpen && (
                <div
                    style={{
                        position: "absolute",
                        top: "100%",
                        marginTop: "7px",
                        left: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.59)",
                        color: "white",
                        borderRadius: "5px",
                        padding: "10px",
                        zIndex: 10,
                        minWidth: "137px",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        fontFamily: 'Monsterrat-Regular'
                    }}
                >

                    <div style={{ fontSize: "12px", fontWeight: "bold", marginBottom: "5px", opacity: 0.7 }}>
                        Land
                    </div>
                    {countries.map((country) => (
                        <div
                            key={country}
                            onClick={() => {
                                document.location.href = '/' + country.language + '-' + country.iso + '/';
                                setDropdownOpen(false);
                            }}
                            style={{
                                padding: "5px",
                                cursor: "pointer",
                                textAlign: "left",
                                borderRadius: "3px",
                                transition: "background 0.2s",
                                backgroundColor: props.activeCountry.id === country.id ? "rgba(255, 255, 255, 0.2)" : "transparent",
                            }}
                            onMouseEnter={(e) => (e.target.style.background = "rgba(255, 255, 255, 0.2)")}
                            onMouseLeave={(e) => (e.target.style.background = props.activeCountry.id === country.id ? "rgba(255, 255, 255, 0.2)" : "transparent")}
                        >
                            {country.name}
                        </div>
                    ))}


                    <hr style={{ margin: "8px 0", borderColor: "rgba(255, 255, 255, 0.2)" }} />


                    <div style={{ fontSize: "12px", fontWeight: "bold", marginBottom: "5px", opacity: 0.7 }}>
                        Språk
                    </div>
                    {languages.map((language) => (
                        <div
                            key={language}
                            onClick={() => {
                                document.location.href = '/' + language.shortCode + '-' + language.country + '/';
                                setDropdownOpen(false);
                            }}
                            style={{
                                padding: "5px",
                                cursor: "pointer",
                                textAlign: "left",
                                borderRadius: "3px",
                                transition: "background 0.2s",
                                backgroundColor: props.activeLanguage.id === language.id ? "rgba(255, 255, 255, 0.2)" : "transparent",
                            }}
                            onMouseEnter={(e) => (e.target.style.background = "rgba(255, 255, 255, 0.2)")}
                            onMouseLeave={(e) => (e.target.style.background = props.activeLanguage.id === language.id ? "rgba(255, 255, 255, 0.2)" : "transparent")}
                        >
                            {language.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};