import React, { useState, useEffect } from 'react';
import { BsBookmarkFill, BsX } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

import Product from '../examples/Product.js'

import '../../style/FavoriteComponent.scss';

const FavoriteComponent = ({ showFavorits, setShowFavorits, favorite, handleFavoriteQuantityChange }) => {
  const { t } = useTranslation();

  const handleCloseFavorits = () => {
    setShowFavorits(false);
  }

  useEffect(() => {
    if (showFavorits) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup to restore scroll on unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showFavorits]);

  return (
    <>
      <div className="favorite-container">
        <div className="favorite-header-icon">
          <BsX size={24} color="#000" onClick={handleCloseFavorits} />
        </div>
        <div className="favorite-header">
          <BsBookmarkFill size={24} />
          <h2 className="favorite-header-text">{t('shop-wishListShortText')}</h2>
        </div>

        <div className="product-list">
          {favorite.products.map((product, index) => (
            <Product
              key={index}
              product={product}
              handleFavoriteQuantityChange={handleFavoriteQuantityChange}
              favorite={favorite}
            />
          ))}
        </div>
        <button className="share-button">Dela din lista</button>
      </div>
      {showFavorits && <div className="overlay"></div>}
    </>
  );
}

export default FavoriteComponent;